import {Typography, Divider} from "@mui/material";
import {Grid} from "@mui/system";
import ConfirmModal from "components/ConfirmModal";
import DefaultCardStructure from "components/DefaultCardStructure";
import ProcessDatePicker from "components/ProcessDatePicker";
import {
  isAfter,
  add,
  differenceInMinutes,
  addMinutes,
  format,
  subMinutes,
} from "date-fns";
import useAlertMessage from "hooks/useAlertMessage";
import useLoading from "hooks/useLoading";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {useState} from "react";
import {useFormContext, useFieldArray, Controller} from "react-hook-form";
import {useParams} from "react-router";
import {
  GetVesselsList,
  DeleteLogisticVoyage,
} from "services/api_v2/Processes/Processes.service";
import {
  CustomIconButton,
  CustomButton,
  CustomInputWrapper,
  CustomAutocomplete,
  CustomInput,
} from "ui";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";
import AddOrEditVoyageModal from "views/ProcessDetails/components/ProcessBoarding/components/Voyages/AddOrEditVoyageModal";
import {AddOrEditVoyageData} from "views/ProcessDetails/components/ProcessBoarding/components/Voyages/AddOrEditVoyageModal/AddOrEditVoyageModal";
import useVoyageTypes from "views/ProcessDetails/components/ProcessBoarding/components/Voyages/useVoyageTypes";
import VoyagesTable from "views/ProcessDetails/components/ProcessBoarding/components/Voyages/VoyagesTable";
import useHandleBoardingOptions from "views/ProcessDetails/components/ProcessBoarding/useHandleBoardingOptions";
import useChangeDAteAndAutocomplete from "views/ProcessDetails/hooks/useChangeDAteAndAutocomplete";
import useHandleFieldLists from "views/ProcessDetails/hooks/useHandleFieldLists";
import {DefaultProcessDetails} from "views/ProcessDetails/Process.types";
import {ID_INITIAL_VOYAGE} from "views/ProcessDetails/utils/constants";
import {getTooltipText} from "views/ProcessDetails/utils/getTooltipText";
import {Process, Voyage} from "../../ProcessSectionView.types";

type Props = {
  refetchProcess: () => Promise<Process | undefined>;
  addNewVoyage: (
    id: number,
    data: Partial<Voyage[]>,
  ) => Promise<Process | undefined>;
};

const ActivityVoyages = (props: Props) => {
  const {control, getValues, formState, watch, reset, resetField} =
    useFormContext<DefaultProcessDetails>();

  const {fields: voyages, remove}: {fields: any; remove: any} = useFieldArray({
    control,
    keyName: "idForm",
    name: "voyages",
  });

  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const [setLoading] = useLoading();

  const voyageWithoutCargoLoad = (index) =>
    !watch(`voyages[${index}].cargoLoad` as any);

  const voyageWithCargoUnload = (index) =>
    !!watch(`voyages[${index}].cargoUnload` as any);

  const {handleChangeAutocomplete, mountOptions} =
    useChangeDAteAndAutocomplete();

  const [boardingOptions, setBoardingOptions] = useState(
    [] as AutoCompleteData[],
  );
  const [vesselsOptions, setVesselsOptions] = useState(
    [] as AutoCompleteData[],
  );

  const [voyageTypes, setVoyageTypes] = useState([] as AutoCompleteData[]);

  const [openVoyageModal, setOpenVoyageModal] = useState(false);
  const [voyageIndex, setVoyageIndex] = useState<null | number>(null);
  const [newVoyage, setNewVoyage] = useState<"" | "newVoyage">("");

  const {getVoyageTypes, loading: loadingVoyageTypes} = useVoyageTypes();

  const disableVoygeTypeOptions = (index: number, option) => {
    if (index === 0) {
      return option.id !== ID_INITIAL_VOYAGE;
    } else {
      return option.id === ID_INITIAL_VOYAGE;
    }
  };

  const {
    getOptions: getBoardingOptions,
    onBottomScroll: onBottomScrollBoarding,
    isLoadingOptions: isLoadingBoardingOptions,
  } = useHandleBoardingOptions();

  const {
    getOptions: getVesselsOptions,
    onBottomScroll: onBottomScrollVessels,
    isLoadingOptions: isLoadingVesselsOptions,
  } = useHandleFieldLists(GetVesselsList);

  const [searchFields, setSearchFields] = useState({
    vessels: "",
    origin: "",
    destination: "",
  });
  const [listView, setListView] = useState(false);

  const handleChangeViewFormat = () => {
    setListView((state) => !state);
  };

  const handleInsertNewVoyage = () => {
    setNewVoyage("newVoyage");
    const processFormHasDirtyFields =
      Object.keys(formState.dirtyFields).length > 0;
    if (processFormHasDirtyFields) {
      handleOpenWarningDirtyFields();
    } else handleOpenVoyageModal(voyages?.length);
  };
  const [initialOrigin, setInitialOrigin] = useState<{
    id: number;
    name: string;
  }>();

  const handleOpenVoyageModal = (index: number | null) => {
    setOpenVoyageModal(true);
    setVoyageIndex(index);
    if (index && index > 0) {
      setInitialOrigin(getValues(`voyages[${index - 1}].destination` as any));
    }
  };

  const [showHelperText, setShowHelperText] = useState({
    typeVoyage: false,
    origin: false,
    destination: false,
  });

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteVoyage = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setDeleteModalIsOpen(true);
  };

  const handleClose = () => {
    setDeleteModalIsOpen(false);
  };
  const confirmDeletionVoyage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticVoyage(itemToDelete?.itemId);
      remove(itemToDelete?.index);

      handleUpdateRenderAfterAddVoyage();
      handleClose();

      showToast("Viagem removida com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const {id: processId} = useParams<{id: string}>();

  const handleUpdateRenderAfterAddVoyage = async () => {
    try {
      const response = await props.refetchProcess();
      reset(response as any, {keepDirtyValues: false, keepValues: false});
      resetField("voyages");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitAndResetProcess = async (dataFromForm) => {
    //Enviando toda a informação das viagens
    const dataToSend = dataFromForm;
    const data = {
      id: dataToSend?.id ?? dataFromForm?.id,
      idTypeVoyage: dataToSend?.typeVoyage?.id,
      idOrigin: dataToSend?.origin?.id,
      idDestination: dataToSend?.destination?.id,
      idVessel: dataToSend?.vessel?.id,
      voyageFlight: dataToSend?.voyageFlight,
      estimatedCargoLoad: dataToSend?.estimatedCargoLoad,
      cargoLoad: dataToSend?.cargoLoad,
      estimatedCargoUnload: dataToSend?.estimatedCargoUnload,
      cargoUnload: dataToSend?.cargoUnload,
      placePickup: dataToSend?.placePickup,
    };

    try {
      setLoading(true);
      await props.addNewVoyage(+processId, data as any);
      await handleUpdateRenderAfterAddVoyage();
      setOpenVoyageModal(false);
      setModalData({});
    } catch (error: any) {
      showAlert(error?.response?.data?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const [modalData, setModalData] = useState<AddOrEditVoyageData>(
    {} as AddOrEditVoyageData,
  );

  const handleEditVoyage = (data: Voyage, index: number) => {
    const dataToEdit: AddOrEditVoyageData = {
      id: data.id,
      typeVoyage: {id: data?.typeVoyage?.id, name: data?.typeVoyage?.name},
      origin: {id: data?.origin?.id, name: data?.origin?.name},
      destination: {id: data?.destination?.id, name: data?.destination?.name},
      vessel: {id: data?.vessel?.id, name: data?.vessel?.name},
      voyageFlight: data?.voyageFlight,
      estimatedCargoLoad: data?.estimatedCargoLoad ?? "",
      cargoLoad: data?.cargoLoad ?? "",
      estimatedCargoUnload: data?.estimatedCargoUnload ?? "",
      cargoUnload: data?.cargoLoad ?? "",
      placePickup: data?.placePickup,
    };
    setNewVoyage("");
    setModalData(dataToEdit);
    setVoyageIndex(index);
    setOpenVoyageModal(true);
  };

  const handleCloseModal = () => {
    setOpenVoyageModal(false);
    setModalData({});
    setInitialOrigin(undefined);
  };

  const [openWarningDirtyFields, setOpenWarningDirtyFields] =
    useState<boolean>(false);

  const handleOpenWarningDirtyFields = () => {
    setOpenWarningDirtyFields(true);
  };

  const handleCloseWarningDirtyFields = () => {
    setOpenWarningDirtyFields(false);
  };

  const handleConfirmWarningDirtyFields = () => {
    handleCloseWarningDirtyFields();
    handleOpenVoyageModal(voyages?.length);
  };

  const etdAndCargoLoadMinDate = (index) => {
    if (index >= 0) {
      if (
        isAfter(
          new Date(voyages[index - 1]?.estimatedCargoUnload),
          new Date(voyages[index - 1]?.cargoUnload),
        )
      ) {
        return add(new Date(voyages[index - 1]?.cargoUnload), {
          hours: 3,
        });
      }
      return add(new Date(voyages[index - 1]?.estimatedCargoUnload), {
        hours: 3,
      });
    } else if (
      isAfter(
        new Date(voyages[voyages.length - 1]?.estimatedCargoUnload),
        new Date(voyages[voyages.length - 1]?.cargoUnload),
      )
    ) {
      return add(new Date(voyages[voyages.length - 1].cargoUnload), {hours: 3});
    } else {
      return add(new Date(voyages[voyages.length - 1].estimatedCargoUnload), {
        hours: 3,
      });
    }
  };

  const etaAndCargoUnloadMinDate = (index) => {
    if (watch(`voyages[${index}].estimatedCargoLoad` as any)) {
      const formattedDate = watch(`voyages[${index}].estimatedCargoLoad` as any)
        ?.split("-")
        ?.join("/");

      return new Date(formattedDate ?? "");
    } else if (index >= 0) {
      if (
        isAfter(
          new Date(voyages[index - 1]?.estimatedCargoUnload),
          new Date(voyages[index - 1]?.cargoUnload),
        )
      ) {
        return add(new Date(voyages[index - 1]?.cargoUnload), {
          hours: 3,
        });
      }
      return add(new Date(voyages[index - 1]?.estimatedCargoUnload), {
        hours: 3,
      });
    } else if (
      isAfter(
        new Date(voyages[voyages.length - 1]?.estimatedCargoUnload),
        new Date(voyages[voyages.length - 1]?.cargoUnload),
      )
    ) {
      return add(new Date(voyages[voyages.length - 1].cargoUnload), {hours: 3});
    } else {
      return add(new Date(voyages[voyages.length - 1].estimatedCargoUnload), {
        hours: 3,
      });
    }
  };

  const getVoyageEditIsDisabled = (index: number) => {
    const dateMinus10Minutes = subMinutes(new Date(), 10);

    const createdAt =
      getValues(`voyages.${index}.createdAt`) === ""
        ? dateMinus10Minutes
        : getValues(`voyages.${index}.createdAt`);
    const actualDate = new Date();

    const difference = differenceInMinutes(actualDate, new Date(createdAt));
    if (difference > 5) {
      return false;
    } else {
      return true;
    }
  };

  const getFormattedCreatedAt = (index: number) => {
    const createdAt = getValues(`voyages.${index}.createdAt`);

    const value = !!createdAt ? new Date(createdAt) : new Date();

    /**
     * Seis minutos para cobrir os segundos que não são exibidos ao usuário.
     */
    const datePlusSixMinutes = addMinutes(value, 6);

    const formatted = format(datePlusSixMinutes, "dd/MM/yyyy, HH:mm");
    return formatted;
  };

  return (
    <>
      {openWarningDirtyFields && (
        <ConfirmModal
          isOpen={openWarningDirtyFields}
          title={"Campos alterados sem salvar"}
          subtitle={
            <Typography>
              Há campos do processo que foram alterados mas não foram salvos.
              Deseja continuar e{" "}
              <Typography sx={{fontWeight: 600, display: "inline-block"}}>
                perder as alterações
              </Typography>{" "}
              ou cancelar para poder salvar?
            </Typography>
          }
          confirmButtonFn={handleConfirmWarningDirtyFields}
          onClose={handleCloseWarningDirtyFields}
          color="warning"
          confirmButtonIconIsDisabled
          confirmButtonText="Continuar"
          titleIcon="warning"
        />
      )}
      {deleteModalIsOpen && (
        <ConfirmModal
          isOpen={deleteModalIsOpen}
          title={"Deletar viagem"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionVoyage()}
          onClose={handleClose}
        />
      )}
      {openVoyageModal && (
        <AddOrEditVoyageModal
          isOpen={openVoyageModal}
          index={voyageIndex}
          handleSubmitVoyage={handleSubmitAndResetProcess}
          handleCloseModal={handleCloseModal}
          newVoyage={newVoyage}
          modalData={modalData}
          initialOrigin={initialOrigin}
        />
      )}
      <DefaultCardStructure
        title="Dados de Booking - Viagens"
        sx={{width: "100%"}}
        isSubcard
        hasDivider={false}
        titleMarginBottom={listView ? 0 : 24}
        button={
          <>
            <CustomIconButton
              iconName={"reorder"}
              onClick={handleChangeViewFormat}
              tooltipText="Visualizar em lista/campos"
              //   disabled={cancelledProcess}
              sx={{
                backgroundColor: listView ? "primary.300" : "background.paper",
                color: listView ? "text.primary" : "primary.main",
                border: "1px solid",
                borderColor: "primary.main",
                marginTop: 0,
              }}
            />
            <CustomButton
              onClickFn={handleInsertNewVoyage}
              startIcon="add"
              size="small"
              //   disabled={cancelledProcess}
            >
              Nova viagem
            </CustomButton>
          </>
        }
      >
        {voyages.length === 0 && (
          <Typography>Não existem viagens no processo.</Typography>
        )}

        {listView ? (
          <VoyagesTable
            handleEditVoyage={handleEditVoyage as any}
            getVoyageEditIsDisabled={getVoyageEditIsDisabled}
          />
        ) : (
          voyages?.map((voyage, index) => {
            return (
              <Grid
                container
                columnSpacing={pxToRem(4)}
                rowSpacing={pxToRem(8)}
                key={`${voyage.idForm}voyages[${index}]`}
              >
                <Grid size={{xs: 12}}>
                  <Typography
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                  >{`Viagem ${index + 1}`}</Typography>
                </Grid>
                <CustomInputWrapper title="Tipo de viagem" xs={3}>
                  <Controller
                    name={`voyages[${index}].typeVoyage`}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        value={value?.name}
                        name={`voyages[${index}].typeVoyage`}
                        options={voyageTypes}
                        getOptionDisabled={(option) =>
                          disableVoygeTypeOptions(index, option)
                        }
                        onOpen={
                          voyageTypes?.length > 0
                            ? undefined
                            : () => getVoyageTypes(setVoyageTypes)
                        }
                        onChange={(e, value) =>
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].typeVoyage`,
                          )
                        }
                        placeholder="Tipo viagem"
                        loading={loadingVoyageTypes}
                        required
                        onBlur={() =>
                          setShowHelperText((prev) => ({
                            ...prev,
                            typeVoyage: false,
                          }))
                        }
                        textFieldProps={{
                          required: true,
                          helperText:
                            showHelperText.typeVoyage && "Preencha este campo.",
                          onInvalid: () =>
                            setShowHelperText((prev) => ({
                              ...prev,
                              typeVoyage: true,
                            })),
                        }}
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Origem" xs={3}>
                  <Controller
                    name={`voyages[${index}].origin` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        optionSecondaryContent
                        name={`voyages[${index}].origin`}
                        placeholder="Origem"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingBoardingOptions}
                        options={mountOptions(value, boardingOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getBoardingOptions(setBoardingOptions, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              origin: value,
                            }));
                            getBoardingOptions(setBoardingOptions, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollBoarding(
                            setBoardingOptions,
                            searchFields.origin,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].origin`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Destino" xs={3}>
                  <Controller
                    name={`voyages[${index}].destination` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        optionSecondaryContent
                        name={`voyages[${index}].destination`}
                        placeholder="Destino"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingBoardingOptions}
                        options={mountOptions(value, boardingOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getBoardingOptions(setBoardingOptions, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              destination: value,
                            }));
                            getBoardingOptions(setBoardingOptions, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollBoarding(
                            setBoardingOptions,
                            searchFields.destination,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].destination`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Navio" xs={3}>
                  <Controller
                    name={`voyages[${index}].vessel` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <CustomAutocomplete
                        name={`voyages[${index}].vessel`}
                        placeholder="Navio"
                        tooltipText={getTooltipText(value?.name)}
                        loading={isLoadingVesselsOptions}
                        options={mountOptions(value, vesselsOptions)}
                        value={value?.name ?? ""}
                        onOpen={() =>
                          getVesselsOptions(setVesselsOptions, 1, "")
                        }
                        onInputChange={(e, value) => {
                          if (e !== null) {
                            setSearchFields((prev) => ({
                              ...prev,
                              vessels: value,
                            }));
                            getVesselsOptions(setVesselsOptions, 1, value);
                          }
                        }}
                        getNextPage={() => {
                          onBottomScrollVessels(
                            setVesselsOptions,
                            searchFields.vessels,
                          );
                        }}
                        onChange={(e, value) => {
                          handleChangeAutocomplete(
                            e,
                            value,
                            `voyages[${index}].vessel`,
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === option?.label
                        }
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Viagem" xs={3}>
                  <Controller
                    name={`voyages[${index}].voyageFlight` as any}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`voyages[${index}].voyageFlight`}
                        placeholder="Viagem"
                        value={value ?? ""}
                        onChange={onChange}
                        size="small"
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="ETD" xs={3}>
                  <Controller
                    name={`voyages[${index}].estimatedCargoLoad` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].estimatedCargoLoad`}
                        title="ETD"
                        minDate={etdAndCargoLoadMinDate(index)}
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Embarque" xs={3}>
                  <Controller
                    name={`voyages[${index}].cargoLoad` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].cargoLoad`}
                        title="Embarque"
                        minDate={etdAndCargoLoadMinDate(index)}
                        maxDate={new Date()}
                        disabled={
                          getVoyageEditIsDisabled(index) ||
                          (voyageWithCargoUnload(index) && value)
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="ETA" xs={3}>
                  <Controller
                    name={`voyages[${index}].estimatedCargoUnload` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].estimatedCargoUnload`}
                        title="ETA"
                        minDate={etaAndCargoUnloadMinDate(index)}
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Desembarque" xs={3}>
                  <Controller
                    name={`voyages[${index}].cargoUnload` as any}
                    control={control}
                    render={({field: {value}}) => (
                      <ProcessDatePicker
                        value={value}
                        name={`voyages[${index}].cargoUnload`}
                        title="Desembarque"
                        minDate={etaAndCargoUnloadMinDate(index)}
                        maxDate={new Date()}
                        disabled={
                          getVoyageEditIsDisabled(index) ||
                          voyageWithoutCargoLoad(index)
                        }
                      />
                    )}
                  />
                </CustomInputWrapper>
                <CustomInputWrapper title="Local de coleta" xs={3}>
                  <Controller
                    name={`voyages[${index}].placePickup` as any}
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <CustomInput
                        name={`voyages[${index}].placePickup`}
                        placeholder="Local de coleta"
                        value={value ?? ""}
                        onChange={onChange}
                        size="small"
                        disabled={getVoyageEditIsDisabled(index)}
                      />
                    )}
                  />
                </CustomInputWrapper>
                <Grid
                  size={{xs: 1}}
                  display="flex"
                  alignItems="end"
                  justifyContent="flex-start"
                >
                  <CustomIconButton
                    tooltipText="Apagar viagem"
                    iconName="delete"
                    disabled={
                      voyage.typeVoyage?.id === ID_INITIAL_VOYAGE ||
                      getVoyageEditIsDisabled(index)
                    }
                    sx={{
                      pr: 0,
                      ":hover": {
                        color: "red",
                      },
                    }}
                    onClick={() => {
                      handleDeleteVoyage(voyage, index);
                    }}
                  />
                </Grid>
                <Grid size={{xs: 12}}>
                  {getVoyageEditIsDisabled(index) && (
                    <Typography variant="label2">
                      {`Viagens só podem ser alteradas após 5 minutos da sua
                      criação,  ${getFormattedCreatedAt(index)}. É necessário recarregar a página.`}
                    </Typography>
                  )}
                </Grid>
                {index < voyages?.length - 1 && (
                  <Grid size={{xs: 12}}>
                    <Divider
                      sx={{
                        width: "85%",
                        margin: `${pxToRem(12)} auto`,
                        display: index < voyages?.length - 1 ? "block" : "none",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })
        )}
      </DefaultCardStructure>
    </>
  );
};

export default ActivityVoyages;
