/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {ReactNode, useState} from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Icon,
  Tooltip,
  SxProps,
  Theme,
  useTheme,
  InputBaseComponentProps,
  Typography,
} from "@mui/material";

import {Visibility, VisibilityOff, Clear} from "@mui/icons-material";
import pxToRem from "hooks/usePxToRem";
import {FormikErrors} from "formik";

export type Props = {
  placeholder?: string;
  name?: string;
  error?: boolean;
  helperText?:
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | string
    | null;
  value?: string | number;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  iconStart?: React.ReactElement | string;
  iconEnd?: React.ReactElement | string | undefined;
  sendIcon?: React.ReactElement | string;
  inputPassword?: boolean;
  disabled?: boolean;
  fnOnClick?: () => void;
  type?: string;
  fnClearInput?: (element: any) => void;
  width?: string;
  height?: string;
  desktopMarginBottom?: number;
  size?: "small" | "medium";
  isMultiline?: boolean;
  rows?: number;
  sx?: SxProps<Theme>;
  iconFontSize?: string;
  required?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  fnOnKeyDown?: () => void;
  inputProps?: InputBaseComponentProps;
  warning?: boolean;
  warningText?: string;
  autoFocus?: boolean;
  customFocus?: Theme;
  onInputClick?: () => void;
  startAdornment?: ReactNode;
  innerRef?: React.RefObject<HTMLInputElement | null>;
  maxDate?: string; // date YYYY-MM-DD
};

const CustomInput = (props: Props) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const {
    disabled = false,
    warningText = "Campo divergente",
    helperText = "Campo obrigatório",
    customFocus,
    type = "text",
    rows = 4,
    size = "medium",
    iconFontSize = pxToRem(18),
    placeholder = "",
    desktopMarginBottom = 0,
    width = "100%",
    height = props.isMultiline ? pxToRem(120 / 3) : "default",
    maxDate,
  } = props;

  const inputTypeConditions = () => {
    const emailCondition = props.name === "email" ? "email" : type;
    return props.inputPassword && !showPassword ? "password" : emailCondition;
  };

  const handleEnterKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.key !== "Enter") return;
    if (props?.fnOnKeyDown) {
      return props.fnOnKeyDown();
    }
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        width: width,
        marginBottom: pxToRem(desktopMarginBottom),
      }}
    >
      <OutlinedInput
        data-testid={props.name}
        autoComplete="off"
        autoFocus={props.autoFocus}
        inputProps={{
          max: maxDate,
          style: {
            height: height,
            fontSize: props?.sx?.["fontSize"] ?? pxToRem(14),
          },
          ...props.inputProps,
        }}
        multiline={props.isMultiline}
        placeholder={placeholder}
        onClick={props.onInputClick}
        error={props.error}
        onKeyDown={handleEnterKeyDown}
        type={inputTypeConditions()}
        value={props.value}
        defaultValue={props.defaultValue}
        rows={rows}
        name={props.name}
        size={size}
        disabled={disabled}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        ref={props.innerRef}
        required={props.required}
        sx={{
          ...props.sx,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${
              props.warning ? theme.palette.warning[500] : customFocus
            }`,
            borderWidth:
              props.warning || props.customFocus ? pxToRem(2) : undefined,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: `${
              props.warning ? theme.palette.warning[500] : props.customFocus
            }`,
          },
        }}
        startAdornment={
          props.startAdornment ??
          (props.iconStart && (
            <InputAdornment position="start">
              <Icon sx={{fontSize: iconFontSize}}>{props.iconStart}</Icon>
            </InputAdornment>
          ))
        }
        endAdornment={
          props.iconEnd || props.inputPassword ? (
            <InputAdornment position="end">
              {props.inputPassword && (
                <IconButton
                  edge="end"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOff sx={{fontSize: iconFontSize}} />
                  ) : (
                    <Visibility sx={{fontSize: iconFontSize}} />
                  )}
                </IconButton>
              )}
              {props.type === "clear" && props.value != "" && (
                <IconButton onClick={props.fnClearInput}>
                  <Clear sx={{fontSize: pxToRem(12)}} />
                </IconButton>
              )}

              {props.iconEnd && (
                <IconButton
                  type="button"
                  edge="end"
                  onClick={props.fnOnClick}
                  disabled={props.disabled}
                  sx={{
                    padding: `0 ${pxToRem(5)} 0 0`,
                    "&:hover": {color: theme.palette.primary.main},
                  }}
                >
                  <Icon sx={{fontSize: iconFontSize}}>{props.iconEnd}</Icon>
                </IconButton>
              )}
            </InputAdornment>
          ) : (
            props.type === "send" &&
            props.value != "" && (
              <InputAdornment position="end">
                <Tooltip title="Enviar" placement="top" arrow>
                  <IconButton
                    type="submit"
                    edge="end"
                    onClick={props.fnOnClick}
                  >
                    <Icon sx={{fontSize: iconFontSize}}>{props.sendIcon}</Icon>
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          )
        }
      />

      {(props.error || props.warning) && (
        <FormHelperText
          sx={{
            m: pxToRem(3),
            color: theme.palette[props.error ? "error" : "warning"][700],
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Foi removido, isso ocupa muito espaço em tela */}
          {/* <Icon sx={{fontSize: pxToRem(20), mr: pxToRem(2)}}>info</Icon> */}
          <Typography variant="body3" component="span">
            {props.error && helperText}
            {props.warning && warningText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(CustomInput);
