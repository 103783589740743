import {forwardRef} from "react";
import pxToRem from "hooks/usePxToRem";

import {TextFieldProps, TextField, Icon, InputAdornment} from "@mui/material";

type CustomInputToSelectDateProps = TextFieldProps & {
  iconSize?: number;
  show?: string;
};

const CustomInputToSelectDate = forwardRef<
  HTMLInputElement | null,
  CustomInputToSelectDateProps
>(function CustomInputToSelectDate(
  {value, size = "small", iconSize, sx, show = value, ...props},
  ref,
) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      size={size}
      ref={ref}
      value={show}
      sx={sx}
      InputProps={{
        style: {fontSize: sx?.["fontSize"] ?? pxToRem(14)},
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              fontSize="small"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontSize: iconSize && pxToRem(iconSize),
              })}
            >
              calendar_month
            </Icon>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});

export default CustomInputToSelectDate;
