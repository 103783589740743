import {Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import {Provider as StoreProvider} from "react-redux";
import {CircularProgress} from "@mui/material";
import configureStore from "./store/configureStore";
import routes from "./routes";
import {PersistGate} from "redux-persist/integration/react";
import {Worker} from "@react-pdf-viewer/core";

const {store, persistor} = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Suspense fallback={<CircularProgress color="secondary" />}>
              {renderRoutes(routes)}
            </Suspense>
          </Worker>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
