import {createActions, createReducer} from "reduxsauce";

type Initial = {
  filters: {
    id: string;
    value: string | string[] | number | number[] | boolean;
  }[];
};

const INITIAL_STATE: Initial = {filters: []};

export const {Types, Creators} = createActions({
  setFilters: ["filters"],
  clearFilters: ["filters"],
});

const setFilters = (state = INITIAL_STATE, action: Initial) => ({
  ...state,
  filters: action.filters,
});

const clearFilters = (state = INITIAL_STATE, action: Initial) => ({
  ...state,
  filters: action.filters,
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_FILTERS]: setFilters,
  [Types.CLEAR_FILTERS]: clearFilters,
});

/**
 * Tem potencial pra virar um 'genericTableReducer'
 * Pode ser uma boa armazenar o estado de forma genérica,
 * utilizando o nome da tabela em questão como chave
 */
