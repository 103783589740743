import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

import {Viewer} from "@react-pdf-viewer/core";
import {toolbarPlugin} from "@react-pdf-viewer/toolbar";
import {pageNavigationPlugin} from "@react-pdf-viewer/page-navigation";
import {Box, Divider, Modal, Stack, Typography, useTheme} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import CustomIconButton from "ui/CustomIconButton";
import {Dispatch, SetStateAction} from "react";

interface FilePreviewProps {
  fileUrl: string;
  setFileUrl: Dispatch<SetStateAction<string>>;
  showPreview: boolean;
  fileName: string;
}

const FilePreview = (props: FilePreviewProps) => {
  const toolbar = toolbarPlugin();
  const pageNavigation = pageNavigationPlugin({enableShortcuts: true});

  const {Toolbar} = toolbar;
  const {CurrentPageInput, NumberOfPages} = pageNavigation;

  const {fileUrl, setFileUrl, fileName} = props;
  const theme = useTheme();

  const headerHeight = pxToRem(64);

  return (
    <>
      {props.showPreview && (
        <Modal open={!!fileUrl} onClose={() => setFileUrl("")}>
          <Stack
            sx={{
              height: "100vh",
              overflowX: "hidden",
              overflowY: "scroll",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              position: "relative",
              ".rpv-core__inner-page": {
                width: "auto !important",
              },

              ".rpv-core__button": {
                backgroundColor: "transparent",
                color: "white",
              },

              ".rpv-core__button:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },

              /* Style the input box */
              ".rpv-core__textbox": {
                background: "transparent",
                filter: "brightness(0.5)",
                color: "white",
              },

              ".rpv-core__minimal-button": {
                color: "white",
              },

              ".rpv-page-navigation__current-page-input": {
                background: "black",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                width: "100vw",
                height: headerHeight,
                padding: `0 ${pxToRem(10)}`,
                position: "fixed",
                top: 0,
                backgroundColor: theme.palette.grey[700],
                opacity: 1,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{gap: pxToRem(16)}}
              >
                <CustomIconButton
                  iconName={"close"}
                  onClick={() => setFileUrl("")}
                  sx={{color: `${theme.palette.background.paper}`}}
                />
                <Typography
                  sx={{
                    fontSize: pxToRem(16),
                    color: `${theme.palette.background.paper}`,
                  }}
                >
                  {fileName}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{gap: pxToRem(16)}}>
                <Toolbar>
                  {(props) => {
                    const {Print} = props;

                    return (
                      <Print>
                        {(props) => (
                          <CustomIconButton
                            {...props}
                            iconName={"print"}
                            sx={{color: `${theme.palette.background.paper}`}}
                          />
                        )}
                      </Print>
                    );
                  }}
                </Toolbar>
              </Stack>
            </Stack>
            <Box
              sx={{
                width: "100vw",
                maxWidth: pxToRem(900),
                marginLeft: pxToRem(130),
                marginBottom: pxToRem(64),
                position: "relative",
                top: headerHeight,
              }}
            >
              <Viewer
                fileUrl={props.fileUrl}
                plugins={[pageNavigation, toolbar]}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                  position: "fixed",
                  left: "40%",
                  top: `calc(100vh - ${pxToRem(8)} - ${headerHeight})`,
                  backgroundColor: `${theme.palette.grey[700]}`,
                  borderRadius: pxToRem(100),
                  padding: `${pxToRem(10)} ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(16)}`,
                  height: pxToRem(52),
                }}
              >
                <Typography sx={{color: theme.palette.background.paper}}>
                  Page
                </Typography>
                <CurrentPageInput />
                <span style={{color: "white"}}>
                  / <NumberOfPages />
                </span>

                <Divider
                  sx={{
                    height: "100%",
                    width: "1px",
                    backgroundColor: theme.palette.background.paper,
                  }}
                />
                <Toolbar>
                  {(props) => {
                    const {ZoomIn, ZoomOut} = props;

                    return (
                      <>
                        <ZoomOut>
                          {(props) => (
                            <CustomIconButton
                              {...props}
                              iconName={"zoom_out"}
                              sx={{color: `${theme.palette.background.paper}`}}
                            />
                          )}
                        </ZoomOut>
                        <ZoomIn>
                          {(props) => (
                            <CustomIconButton
                              {...props}
                              iconName={"zoom_in"}
                              sx={{color: `${theme.palette.background.paper}`}}
                            />
                          )}
                        </ZoomIn>
                      </>
                    );
                  }}
                </Toolbar>
              </Box>
            </Box>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default FilePreview;
