import {DatePickerValues} from "components/CustomDatePicker/CustomDatePicker";
import {format} from "date-fns";
import React from "react";
import {useFormContext} from "react-hook-form";
import {AutoCompleteData} from "ui/CustomAutoComplete/CustomAutoComplete";

const useChangeDAteAndAutocomplete = () => {
  const {setValue} = useFormContext();

  const handleChangeDateField = (
    dates: DatePickerValues,
    inputName: string,
  ) => {
    if (dates.startDate === null) {
      return;
    }
    const date = !dates?.startDate ? "" : format(dates.startDate, "yyyy-MM-dd");
    setValue(inputName, date, {shouldDirty: true});
  };

  const handleChangeTypeVoyageToCollect = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    name: string,
    voyageId: number,
  ) => {
    /**
     * Precedence está sendo ordenado via backend, desabilitarei aqui por ora.
     */
    // if (value.label?.toLowerCase() === "coleta") {
    //   setValue(
    //     name,
    //     {
    //       id: voyageId,
    //       typeVoyage: {id: value?.id, name: value?.label},
    //       precedence: 1,
    //     },
    //     {shouldDirty: true},
    //   );
    //   return;
    // }
    setValue(name, {id: value?.id, name: value?.label}, {shouldDirty: true});
  };

  const handleChangeAutocomplete = (
    _: React.SyntheticEvent<Element, Event>,
    value: any,
    name: string,
  ) => {
    if (value === null) {
      setValue(name, {id: null, name: null}, {shouldDirty: true});
      return;
    }
    setValue(name, {id: value?.id, name: value?.label}, {shouldDirty: true});
  };

  const handleDeleteDateField = (inputName: string) => {
    setValue(inputName, null, {shouldDirty: true});
  };

  const handleChangeMultiValueAutocomplete = (value: any, name: string) => {
    if (value === null) {
      setValue(name, {id: undefined, name: undefined}, {shouldDirty: true});
      return;
    }
    setValue(name, value, {shouldDirty: true});
  };

  const handleChangeDateDefault = (
    date: string | Date | null,
    inputName: string,
  ) => {
    if (date === null) {
      setValue(inputName, null, {
        shouldDirty: true,
      });
    } else {
      setValue(inputName, format(new Date(date ?? ""), "yyyy-MM-dd"), {
        shouldDirty: true,
      });
    }
  };

  const mountOptions = (actualValue: any, options: AutoCompleteData[]) => {
    const filteredOptions = options.filter(
      (item) => item?.id !== actualValue?.id,
    );

    if (actualValue?.id === undefined) return [...filteredOptions];
    return [
      {id: actualValue?.id, label: actualValue?.name ?? ""},
      ...filteredOptions,
    ];
  };

  return {
    handleChangeAutocomplete,
    handleChangeDateField,
    handleDeleteDateField,
    handleChangeDateDefault,
    mountOptions,
    handleChangeMultiValueAutocomplete,
    handleChangeTypeVoyageToCollect,
  };
};

export default useChangeDAteAndAutocomplete;
