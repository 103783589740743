import {
  Tooltip,
  FormControl,
  DialogContent,
  Autocomplete,
  TextField,
  Typography,
  Chip,
  Divider,
  useTheme,
} from "@mui/material";
import {Grid} from "@mui/system";
import CustomModal from "components/CustomModal";
import {DefaultModalFooter} from "components/CustomModal/components";
import pxToRem from "hooks/usePxToRem";
import {createRef, useState} from "react";
import {CustomInputWrapper, CustomInput, CustomButton} from "ui";
import {downloadDocumentFromUrl} from "util/downloadDocumentFromUrl";
import ConfirmModal from "components/ConfirmModal";
import {EmailPreview} from "components/ModalCompleteActivity/components";
import {splitAndRemoveSpacesFromString} from "util/splitAndRemoveSpacesFromString";
import {AttachFiles, FileBigPreview} from "components";

export type EmailData = {
  subject: string;
  to: string[];
  cc: string[];
  bcc: string[];
  attachment: {name: string; url: string; id: string}[];
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  processId: number;
  processNumber?: string;
  typeOfEmail?: string;
  modalIcon?: string;
  handleSendEmail: (
    formRef: React.RefObject<HTMLFormElement>,
    emailData: EmailData,
    editedEmail: string,
  ) => void;
  emailData: EmailData;
  setEmailData: React.Dispatch<React.SetStateAction<EmailData>>;
  emailReturn: string;
  formRef: React.RefObject<HTMLFormElement>;
  handleAbortFollowUp?: () => void;
};

const EmailPreviewModal = (props: Props) => {
  const {
    isOpen,
    handleClose,
    processNumber = "",
    typeOfEmail = "follow up",
    modalIcon = "outgoing_mail",
    handleSendEmail,
    emailData,
    emailReturn,
    formRef,
    handleAbortFollowUp = handleClose,
    processId,
  } = props;

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [idAttachToRemove, setIdAttachToRemove] = useState("");
  const [localEmailData, setLocalEmailData] = useState(emailData);

  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");

  const editorRef = createRef<HTMLDivElement>();

  const handleConfirmRemoveAttach = (id: string) => {
    setConfirmModalIsOpen(true);
    setIdAttachToRemove(id);
  };

  const handleDeleteAttachment = (id: string) => {
    const newEmailData: EmailData = {
      ...localEmailData,
      attachment: localEmailData.attachment.filter((item) => item.id !== id),
    };

    setLocalEmailData(newEmailData);
    setConfirmModalIsOpen(false);
  };

  const theme = useTheme();

  const handleAutoComplete = (_: unknown, nv, field: string) => {
    const newValues = splitAndRemoveSpacesFromString(nv[nv.length - 1]) ?? [];
    const fullArray = [...nv.slice(0, -1), ...newValues];

    setLocalEmailData((prev) => ({...prev, [field]: fullArray}));
  };

  const handleChangeSubject = (_: unknown, nv, field: string) => {
    setLocalEmailData((prev) => ({...prev, [field]: nv}));
  };

  const handleClickFileChip = (item: {name: string; url: string}) => {
    if (item.name?.includes("pdf")) {
      setFileUrl(item.url);
      setFileName(item.name);
    } else {
      downloadDocumentFromUrl(item?.url, item?.name);
    }
  };

  return (
    <>
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        title="Remover anexo"
        subtitle="Essa ação não pode ser desfeita!"
        confirmButtonFn={() => handleDeleteAttachment(idAttachToRemove)}
        onClose={() => setConfirmModalIsOpen(false)}
      />
      {!!fileUrl && (
        <FileBigPreview
          fileName={fileName}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
          showPreview={!!fileUrl}
        />
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={handleClose}
        title={`Enviar ${typeOfEmail}`}
        titleIcon={modalIcon}
        subtitle={`Encaminhar ${typeOfEmail} para o processo ${processNumber}`}
        maxWidth="lg"
      >
        <FormControl
          component="form"
          onSubmit={() => {
            handleSendEmail(
              formRef,
              localEmailData,
              editorRef.current?.innerHTML ?? "",
            );
          }}
          id="mainForm"
          ref={formRef}
        >
          <Grid container size={12}>
            <Grid size={4}>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: pxToRem(12),
                  paddingTop: pxToRem(7),
                  maxHeight: pxToRem(400),
                }}
              >
                <Grid
                  container
                  rowGap={pxToRem(8)}
                  sx={{
                    maxHeight: pxToRem(400),
                    overflowY: "unset",
                  }}
                >
                  <>
                    <CustomInputWrapper title="Assunto" xs={12}>
                      <CustomInput
                        name="subject"
                        value={localEmailData?.subject ?? ""}
                        size="small"
                        placeholder="Assunto"
                        required
                        onChange={(e) =>
                          handleChangeSubject(e, e.target.value, "subject")
                        }
                      />
                    </CustomInputWrapper>
                    <CustomInputWrapper title="Para" xs={12}>
                      <Autocomplete
                        multiple
                        size="small"
                        freeSolo
                        autoSelect
                        placeholder="Para"
                        value={localEmailData?.to ?? []}
                        limitTags={5}
                        onChange={(e, newValue) =>
                          handleAutoComplete(e, newValue, "to")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={localEmailData?.to?.length === 0}
                            placeholder="Para"
                          />
                        )}
                        options={[]}
                      />
                    </CustomInputWrapper>
                    <CustomInputWrapper title="CC" xs={12}>
                      <Autocomplete
                        multiple
                        size="small"
                        freeSolo
                        placeholder="CC"
                        autoSelect
                        value={localEmailData?.cc ?? []}
                        onChange={(e, newValue) =>
                          handleAutoComplete(e, newValue, "cc")
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="CC" />
                        )}
                        options={[]}
                      />
                    </CustomInputWrapper>
                    <CustomInputWrapper title="BCC" xs={12}>
                      <Autocomplete
                        multiple
                        size="small"
                        freeSolo
                        autoSelect
                        placeholder="BCC"
                        value={localEmailData?.bcc ?? []}
                        onChange={(e, newValue) =>
                          handleAutoComplete(e, newValue, "bcc")
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="BCC" />
                        )}
                        options={[]}
                      />
                    </CustomInputWrapper>

                    <CustomInputWrapper title="Anexos" xs={12}>
                      <AttachFiles
                        emailData={localEmailData}
                        setEmailData={setLocalEmailData}
                        processId={processId}
                      />
                      {localEmailData?.attachment.length === 0 && (
                        <Typography variant="subtitle3">Sem anexos</Typography>
                      )}
                      <Grid container size={12} gap={pxToRem(8)}>
                        {localEmailData?.attachment.map((item, index) => (
                          <Grid key={item?.id ?? index}>
                            <Tooltip title={item?.name} arrow placement="top">
                              <Chip
                                label={item?.name}
                                size="medium"
                                variant="filled"
                                clickable
                                onClick={() => handleClickFileChip(item)}
                                onDelete={() =>
                                  handleConfirmRemoveAttach(item?.id)
                                }
                                sx={{
                                  maxWidth: pxToRem(150),
                                  backgroundColor: theme.palette.primary[50],
                                  color:
                                    theme.palette.mode === "dark"
                                      ? theme.palette.primary[600]
                                      : theme.palette.primary[700],
                                  ":hover": {
                                    backgroundColor: theme.palette.primary[50],
                                    opacity: 0.9,
                                  },
                                  "& .MuiChip-deleteIcon": {
                                    marginLeft: pxToRem(10),
                                  },
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                    </CustomInputWrapper>
                  </>
                </Grid>
              </DialogContent>
            </Grid>
            <Grid
              size={0.5}
              display="flex"
              justifyContent="center"
              p={`${pxToRem(10)} 0`}
            >
              <Divider orientation="vertical" />
            </Grid>
            <Grid
              size={7.5}
              sx={{
                height: pxToRem(400),
                overflowY: "scroll",
                display: "unset",
              }}
            >
              <EmailPreview emailContent={emailReturn} editorRef={editorRef} />
            </Grid>
            <DefaultModalFooter>
              <Grid
                container
                size={12}
                gap={pxToRem(8)}
                justifyContent="flex-end"
              >
                <Grid>
                  <CustomButton
                    variant="outlined"
                    onClickFn={handleAbortFollowUp}
                  >
                    {"Cancelar"}
                  </CustomButton>
                </Grid>
                <Grid>
                  <CustomButton
                    onClickFn={() =>
                      handleSendEmail(
                        formRef,
                        localEmailData,
                        editorRef.current?.innerHTML ?? "",
                      )
                    }
                  >
                    Enviar Email
                  </CustomButton>
                </Grid>
              </Grid>
            </DefaultModalFooter>
          </Grid>
        </FormControl>
      </CustomModal>
    </>
  );
};

export default EmailPreviewModal;
