import {Box, Skeleton} from "@mui/material";
import ProcessSectionView from "./ProcessSectionView";
import useProcessSectionViewData from "./useProcessSectionViewData";
import pxToRem from "hooks/usePxToRem";
import {AvailableUiComponents} from "components/ModalCompleteActivity/CompleteActivity.types";

type Props = {
  processId: number;
  componentToRender: AvailableUiComponents | undefined;
  handleCloseDrawer: (uiComponent: AvailableUiComponents | undefined) => void;
};

const ProcessSectionViewHandleData = (props: Props) => {
  const {processId, componentToRender, handleCloseDrawer} = props;
  const {defaultValues, isLoading, refetchProcess, patchProcess, addNewVoyage} =
    useProcessSectionViewData(processId);

  if (isLoading)
    return (
      <Box sx={{width: "100%", p: pxToRem(20)}}>
        <Skeleton animation="wave" width={pxToRem(150)} height={pxToRem(50)} />
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <Skeleton animation="wave" key={item} height={pxToRem(50)} />
        ))}
        <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
          <Skeleton
            animation="wave"
            width={pxToRem(150)}
            height={pxToRem(50)}
          />
        </Box>
      </Box>
    );
  return (
    <ProcessSectionView
      defaultValues={defaultValues}
      refetchProcess={refetchProcess}
      processId={processId}
      componentToRender={componentToRender}
      handleCloseDrawer={handleCloseDrawer}
      patchProcess={patchProcess}
      addNewVoyage={addNewVoyage as any}
    />
  );
};

export default ProcessSectionViewHandleData;
