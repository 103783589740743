const useMasks = () => {
  const cnpjMask = (cnpj: string) => {
    const cnpjWithoutFormatting = cnpj
      .split(".")
      .join("")
      .split("/")
      .join("")
      .split("-")
      .join("");

    const masked = `${cnpjWithoutFormatting[0]}${cnpjWithoutFormatting[1]}.${cnpjWithoutFormatting[2]}${cnpjWithoutFormatting[3]}${cnpjWithoutFormatting[4]}.${cnpjWithoutFormatting[5]}${cnpjWithoutFormatting[6]}${cnpjWithoutFormatting[7]}/${cnpjWithoutFormatting[8]}${cnpjWithoutFormatting[9]}${cnpjWithoutFormatting[10]}${cnpjWithoutFormatting[11]}-${cnpjWithoutFormatting[12]}${cnpjWithoutFormatting[13]}`;
    return masked;
  };

  const cpfMask = (cpf: string) => {
    const masked = `${cpf[0]}${cpf[1]}${cpf[2]}.${cpf[3]}${cpf[4]}${cpf[5]}.${cpf[6]}${cpf[7]}${cpf[8]}-${cpf[9]}${cpf[10]}`;
    return masked;
  };

  return {cnpjMask, cpfMask};
};

export default useMasks;
